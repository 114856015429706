$xs: 375px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

@function vw($size) {
  @return calc($size / 375px * 100vw);
}


@mixin tablet {
  @media screen and (max-width: $lg) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $xs) {
    @content;
  }
}

@function rem($size) {
  @return calc(#{$size} / 16px * 1rem);
}

.react-datepicker {
  width: 460px;
  border: 1px solid white;

  @include tablet {
    width: 100%;

  }


  .react-datepicker__aria-live {
    display: none;
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }

  .datepicker-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 88px;
    margin: 28px 0 37px;

    @include tablet {
      padding: 0;
      width: calc(207.55 / 335 * 100%);
      margin: 25.61px auto 23.2px;
    }

    .current-month {
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
      color: white;
      mix-blend-mode: difference;
      letter-spacing: 0.48px;
      text-transform: uppercase;

      @include tablet {
        font-size: vw(16px);
        line-height: 1;
        letter-spacing: 0.32px;


      }

      @include mobile {
        font-size: 16px;
      }
    }

    button {
      cursor: pointer;

      @include tablet {


        i {
          width: vw(24px);
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  .react-datepicker__day-names {
    display: flex;
    padding: 0 31px;
    margin-bottom: 8px;

    @include tablet {
      padding: 0 24px;
      margin-bottom: 5.83px;
    }

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 50px;
      height: 50px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1;
      color: white;

      @include tablet {
        width: vw(36px);
        height: auto;
        aspect-ratio: 1/1;
        font-size: vw(14px);
      }

      @include mobile {
        width: 36px;
        font-size: 14px;
      }
    }
  }

  .react-datepicker__month {
    padding: 0 31px 31px;

    @include tablet {
      padding: 0 24px 25px;
    }

    .react-datepicker__week {
      display: flex;

      +.react-datepicker__week {
        margin-top: 8px;
      }

      .react-datepicker__day {
        flex: 1;
        width: 50px !important;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        color: white;

        @include tablet {
          width: vw(36px) !important;
          // max-width: 36px;
          height: auto;
          aspect-ratio: 1 / 1;
          font-size: vw(14px);




        }

        @include mobile {
          font-size: 14px;
          width: 36px !important;
        }

        &.react-datepicker__day--outside-month {
          color: #5F5F5F;
        }
      }

      .react-datepicker__day--range-start {
        background-color: #131CDE;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }

      .react-datepicker__day--in-range {
        background-color: #131CDE;

      }

      .react-datepicker__day--range-end {
        background-color: #131CDE;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }
    }
  }


}
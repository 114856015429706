// @import "./assets/scss/_utils.scss";
// @import "./assets/scss/reset.scss";
// @import "react-modern-calendar-datepicker/lib/DatePicker.css";
// @import "font-awesome/css/font-awesome.css";
// @import "react-datepicker/dist/react-datepicker.css";

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-display: swap;
  src: local("Pretendard Black"),
    url("./assets/fonts/woff2/Pretendard-Black.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Black.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Black.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard ExtraBold"),
    url("./assets/fonts/woff2/Pretendard-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-ExtraBold.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"),
    url("./assets/fonts/woff2/Pretendard-Bold.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Bold.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"),
    url("./assets/fonts/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-SemiBold.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"),
    url("./assets/fonts/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Medium.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url("./assets/fonts/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Regular.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-display: swap;
  src: local("Pretendard Light"),
    url("./assets/fonts/woff2/Pretendard-Light.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Light.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Light.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-display: swap;
  src: local("Pretendard ExtraLight"),
    url("./assets/fonts/woff2/Pretendard-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-ExtraLight.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-display: swap;
  src: local("Pretendard Thin"),
    url("./assets/fonts/woff2/Pretendard-Thin.woff2") format("woff2"),
    url("./assets/fonts/woff/Pretendard-Thin.woff") format("woff"),
    url("./assets/fonts/otf/Pretendard-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Faktum Test";
  src: url("./assets/fonts/FaktumTest-regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Faktum Test";
  src: url("./assets/fonts/FaktumTest-bold.otf");
  font-weight: 500;
  font-style: normal;
}

html,
body {
  //min-height: 100%;
  // font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움",
  //   arial, sans-serif;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  counter-reset: section;
  word-spacing: 1px;
  line-height: 1.5;

  -webkit-touch-callout: none;
  //height: 100vh;

  /* 전역 background 설정 */
  // background-color: #f3f4f4;
  // background-color: #000;

  // background-color: ${theme.palette.blue};
}

.App {
  &.en {
    &>* {
      font-family: "Faktum Test", "Malgun gothic", "맑은고딕", Dotum, "돋움",
        arial, sans-serif !important;
    }
  }

  &.ko {
    &>* {
      font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움",
        arial, sans-serif !important;
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  body {
    min-width: 1280px;
    overflow-x: auto;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}

em {
  font-style: normal;
}

ol,
ul {
  li {
    list-style: none;
  }
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.hidden {
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px 0 0 -1px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

.blind {
  clear: both;
  position: absolute;
  top: auto;
  left: 0;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;

  &:after {
    display: none;
  }
}

a:hover {
  text-decoration: none !important;
}

image {
  max-width: 100%;
}

a,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

#root {
  position: relative;
  // min-height: 100%;
}

.App {
  position: relative;
}

.App-header {
  position: relative;
  height: rem(60px);
  z-index: 1;

  .logo {
    a {
      display: block;
    }
  }
}

.App-body {
  display: flex;
}

.App-contents {
  margin: 0 auto;
  overflow: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.no-gap {
  gap: 0;
}

.gap-10 {
  gap: 0.625rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-30 {
  gap: 1.875rem;
}

.gap-40 {
  gap: 2.5rem;
}

.gap-50 {
  gap: 3.125rem;
}

.flex-1 {
  flex: 1;
}

.ml-10 {
  margin-left: 0.625rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.pl-10 {
  padding-left: 0.625rem;
}

.pr-10 {
  padding-right: 0.625rem;
}

.pb-10 {
  padding-bottom: 0.625rem;
}

.pt-10 {
  padding-top: 0.625rem;
}

.w-100 {
  width: 100%;
}

.temporary-nav-bar {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 0.5;
  z-index: 99999;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;

  // TODO:: 호버 시 width 넓어지게

  &:hover {
    width: 10px;
  }
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border: solid 1px #fff;
  background-color: #1011dd;
}

html {
  // &.b {
  //   ::-webkit-scrollbar {
  //     width: 5px;
  //     background-color: #ffffff;

  //   }

  //   ::-webkit-scrollbar-thumb {
  //     border: solid 1px #fff;
  //     background-color: #000;
  //   }
  // }

  &.b {
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #ffffff;

      &:hover {
        width: 10px;
      }
    }

    ::-webkit-scrollbar-thumb {
      border: solid 1px #fff;
      background-color: #000;
    }
  }
}

body {
  width: 100%;
  overflow: clip;
  overflow-y: scroll;

  @media (max-width: 1024px) {}
}

body {
  &.scroll-hidden {
    ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.App {
  @media (max-width: 1024px) {
    overflow-x: clip;

  }
}

// main {
//   &.scroll-lock {
//     height: 100vh;
//     overflow: hidden;
//   }
// }